import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import { IWinzoStoreOfferComponentProps } from "../../../lib/types/components";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import DottedGrid from "../../common/Utils/DottedGrid";

const WinzoStoreOfferComponentV2 = (props: IWinzoStoreOfferComponentProps) => {

    const {winzoStoreData} = props;

    return (
        winzoStoreData && Object.keys(winzoStoreData).length ?
            <H2TrapeziumBG uniqueContainerId={"winzoStore_container"}>
                <style jsx>
                    {`
                      .mainContainer {
                        padding: 18px 0px 0px;
                        background-color: #ebebeb;
                        font-family: var(--font-primary);
                      }

                      .headingParent {
                        display: flex;
                        justify-content: center;
                      }

                      .heading {
                        font-size: ${dynamicCssSize(60, 16)};
                        text-transform: uppercase;
                        font-weight: 900;
                        font-style: italic;
                        letter-spacing: 2.25px;
                        max-width: 65vw;
                        text-align: center;
                        color: #35255a;
                      }

                      .sliderContainer {
                        padding: ${dynamicCssSize(85, 20)} 0px 90px;
                      }

                      /* Hide scrollbar for Chrome, Safari and Opera */
                      .innerSliderContainer::-webkit-scrollbar {
                        display: none;
                      }

                      /* Hide scrollbar for IE, Edge and Firefox */
                      .innerSliderContainer {
                        -ms-overflow-style: none; /* IE and Edge */
                        scrollbar-width: none; /* Firefox */
                      }

                      .innerSliderContainer {
                        display: flex;
                        overflow: scroll;
                        width: 100%;
                        height: 100%;
                      }

                      .sliderItem {
                        width: 100%;
                        height: 100%;
                        margin-right: 35px;
                      }

                      .imgContainer {
                        width: ${dynamicCssSize(480, 110)};
                        height: ${dynamicCssSize(260, 65)};
                        position: relative;
                      }

                      .itemName {
                        opacity: 0.7;
                        font-size: ${dynamicCssSize(32, 10)};
                        font-weight: 500;
                        letter-spacing: -0.51px;
                        text-align: center;
                        color: #333333;
                        padding: 20px 0px;
                      }

                      @media screen and (max-width: 767px) {
                        .heading {
                          line-height: 0.75;
                          letter-spacing: 1px;
                        }

                        .itemName {
                          font-weight: 600;
                          padding: 8px 0px;
                          letter-spacing: -0.14px;
                        }
                        .sliderContainer{
                          padding: 20px 0px 30px;
                        }
                        .sliderItem {
                          margin-right: 12px;
                        }
                      }
                    `}
                </style>
                <div className={"container-fluid mainContainer"}>
                    <DottedGrid color={"#d8d8d8"} lines={2} cssMobileStyle={{
                        display: "none"
                    }} cssDesktopStyle={{
                        display: "block",
                        position: "absolute",
                        top: "5vw",
                        left: "3vw",
                        zIndex: 1000,
                    }}/>
                    <div className={"container"}>
                        <div className={"headingParent row"}>
                            <h2 className={"heading"}>{winzoStoreData.heading}</h2>
                        </div>
                        <div className={"sliderContainer"}>
                            <div className={"innerSliderContainer"}>
                                {
                                    winzoStoreData.imageData.map((item, index) => {
                                        return (
                                            <div key={"winzoStoreData.imageData_" + index} className={"sliderItem"}>
                                                <div className={"imgContainer"}>
                                                    <CustomImageComponent
                                                        src={item.src}
                                                        alt={`${item.text1} Offers`}
                                                        layout={'fill'}
                                                        objectFit={'contain'}
                                                    />
                                                </div>
                                                <p className={"itemName"}>{item.text1}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </H2TrapeziumBG>
            :
            <></>
    );
}

export default  WinzoStoreOfferComponentV2;
